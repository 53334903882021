<template>
  <div class="schedule-min" @dragover="disableDragendAnimation">
    <div class="d-flex justify-content-between">
      <h1>Schedule</h1>
      <div
        v-if="getCurrentScopes.indexOf('st2.betafeatures') !== -1"
        class="calendar-btn-mode ml-1 pl-1"
        @click="goToViewTimesheet()"
      >
        <span>
          <span>View Timesheet</span>
        </span>
      </div>
    </div>
    <h2>{{ userObj.firstName + " " + userObj.lastName }}</h2>
    <br/>
    <div>

      <div class="time-off-board" v-if="getUserType === 'User'">
        <h6 class="font-weight-bolder">TIME OFF</h6>
        <div class="day-off-card-wrapper">
          <DayOffCard
            v-for="day in dayOffs"
            :key="day.id"
            :startDateItem="day.startDate"
            :endDateItem="day.endDate"
            :id="day.id"
            @remove-day-off="removeModal"
          />
        </div>
        <div
          v-if="!showAll"
          class="show-all-btn"
          @click="showAllDayOff"
        >Show All...</div>
        <div
          v-else
          class="show-all-btn"
          @click="hideAllDayOff"
        >Hide...</div>
        <b-button
          type="button"
          variant="primary"
          @click.prevent="addTimeOff()"
        >
          Add New
        </b-button>
      </div>

      <div class="sc-main-box" :style="{width: '82%'}">
        <div class="week-pagination">
          <div class="arrow-wrapper" @click="changeWeek(false)">
            <feather-icon
              icon="ChevronsLeftIcon"
              size="21"
              style="color: white"
            />
          </div>
          <div class="wrap-datepicker">
            <b-form-datepicker
              v-model="DatePicker"
              class="datepicker"
              button-variant="primary"
              button-only
              size="sm"
            />
          </div>
          <span class="current-week">Week of: {{ settingData.startDate + " - " + settingData.endDate }}</span>
          <div class="arrow-wrapper" @click="changeWeek(true)">
            <feather-icon
              icon="ChevronsRightIcon"
              size="21"
              style="color: white"
            />
          </div>
        </div>


        <div class="sc-main-scroll" :style="{width: 'fit-content'}">
          <div class="sc-main">
            <div
              class="timeline"
              :style="{height: '4rem', background: '#6187ae', display: 'flex'}"
            >
              <div
                v-for="n in dateCnt"
                :key="n"
                class="sc-time"
                :style="timelineDate(n - 2) ?
                  {background: 'blue', width: dateDivW + 'vw', fontWeight: 'bold', fontSize: '1rem'} :
                  {width: dateDivW + 'vw', fontWeight: 'bold', fontSize: '1rem'}"
              >
                <div v-if="timelineDate(n - 2)">
                  <div class="today-date">{{ timelineDate(n - 2) }}</div>
                  <div class="today-date">{{ getHeaderDate(n - 2) }}</div>
                </div>
                <div v-else>
                  {{ getHeaderDate(n - 2) }}
                </div>
              </div>
            </div>

            <div class="scroll-container-person">
              <div
                v-for="(row, index) in scheduleData"
                :key="index"
                :class="'timeline'"
                :style="{'height': settingData.rowH + 'rem'}"
              >
                <UnitBlock
                  v-for="n in unitCnt"
                  :key="'unit' + n"
                  :row-index="index"
                  :key-index="n"
                  :dayOff="isDayOff(row, n)"
                  :width="settingData.unitDivW + 'vw'"
                  @set-dragenter-row-and-index="setDragenterRowAndIndex"
                ></UnitBlock>

                <ShortReservedBlock
                  v-for="(detail, keyNo) in row.schedule"
                  :key="'res' + keyNo"
                  :schedule-detail="detail"
                  :row-index="index"
                  :key-no="keyNo"
                  :start-text="detail.start"
                  :end-text="detail.end"
                  :unit-width="settingData.unitDivW"
                  :border-width="settingData.borderW"
                  :min-date="settingData.startDate"
                  :unit="settingData.unit"
                  :dragenter-row-index="dragenterRowIndex"
                  :dragenter-column-index="dragenterColumnIndex"
                  :is-selecting="isSelecting"
                  :is-selecting-row-index="isSelectingRowIndex"
                  :is-selecting-index="isSelectingIndex"
                  @set-dragenter-row-and-index="setDragenterRowAndIndex"
                  @move-schedule-data="moveScheduleData"
                  @edit-event="$emit('edit-event', detail.start, detail.end)"
                  @click-event="$emit('click-event', detail.start, detail.end, detail.text, detail.data)"
                ></ShortReservedBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br class="clear" />
    </div>

    <b-modal
      id="modal-add-time-off"
      ok-variant="primary"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-primary"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="validateAddTimeOff"
      @cancel="cancelAddTimeOff"
    >
      <div class="text-center mb-2">
        <div>This is only for Action Assignments in SmartTrak.</div>
        <div>All PTO must be submitted and approved in ADP first.</div>
      </div>
      <div>Time Off Range:</div>

      <b-row class="mt-2">
        <b-col>
          <b-form-group
            label="Start Date"
            label-for="startDate"
            class="date-input"
          >
            <b-form-input
              id="startDate"
              v-model="addTimeRange.startDateItem"
              type="date"
              placeholder="Start Date"
              name="startDate"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="End Date"
            label-for="endDate"
            class="date-input"
          >
            <b-form-input
              id="endDate"
              v-model="addTimeRange.endDateItem"
              type="date"
              placeholder="End Date"
              name="endDate"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-modal>

    <b-modal
      id="modal-remove-time-off"
      ok-variant="danger"
      ok-title="Ok"
      cancel-title="Cancel"
      modal-class="modal-danger"
      hide-header-close
      centered
      @ok="confirmRemove"
      @cancel="cancelRemove"
    >
      <div>Are you sure you want to delete this day off?</div>
    </b-modal>

  </div>
</template>

<script>
import APIService from "@core/utils/APIService";
import SchedulesService from "./assetsSchedules"
import UnitBlock from "./UnitBlock";
import ShortReservedBlock from "./ShortReservedBlock";
import {BButton, BCol, BFormDatepicker, BFormGroup, BFormInput, BModal, BRow} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DayOffCard from "@/views/schedule/DayOffCard.vue";
import {mapGetters} from "vuex";

const ScheduleService = new SchedulesService();

const apiService = new APIService();

export default {
  components: {
    DayOffCard,
    ShortReservedBlock,
    UnitBlock,
    BFormDatepicker,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  data() {
    return {
      userID: "",
      userObj: {},
      scheduleData: [],
      firstData: 0,
      lastData: 0,
      settingData: {
        startDate: "2020/04/20",
        endDate: "2020/04/26",
        unit: 1440,
        borderW: 1,
        unitDivW: 9,
        rowH: 5.5
      },
      dateDivW: 0,
      dateCnt: 0,
      unitCnt: 0,
      isSelecting: false,
      isSelectingRowIndex: null,
      isSelectingIndex: null,
      dragenterRowIndex: null,
      dragenterColumnIndex: null,
      disabledPagination: false,
      DatePicker: "",
      inputDate: new Date(),
      dayOffs: [],
      showAll: false,

      idFromRemove: null,

      addTimeRange: {
        startDateItem: null,
        endDateItem: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
      getUserType: "auth/getUserType",
    })
  },
  watch: {
    DatePicker(val) {
      this.inputDate = val;
      this.getCurrentWeek(val);
      this.getUserSchedule();
    },
  },
  created() {
    this.userID = this.$route.params.userID;
    const searchDate = this.$route.params.date;
    this.getCurrentWeek(isNaN(new Date(searchDate).getTime()) ? null : searchDate);
    this.getUser();
    this.dateCnt =
        ScheduleService.getDateDiff(
            new Date(this.settingData.startDate),
            new Date(this.settingData.endDate)
        );
    let oneDayCnt = 1;
    this.unitCnt = this.dateCnt;
    this.dateDivW =
        this.settingData.unitDivW * oneDayCnt +
        (oneDayCnt - this.settingData.borderW);
    this.getUserSchedule()
    this.getTimeOff()
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getUser() {
      if (this.userID) {
        apiService
            .get("user/" + this.userID + "/short")
            .then( res => {
              this.userObj = res.data;
            })
      } else {
        this.showToast('danger', 'top-center', 4000, 'No schedule for unspecified user.');
      }
    },
    getUserSchedule() {
      if (!this.userID) {
        this.showToast('danger', 'top-center', 4000, 'No schedule for unspecified user.');
        return;
      }
      this.disabledPagination = true;
      this.scheduleData = [];
      const patch = `schedule/user-actions?UserId=${this.userID}&StartDate=${this.settingData.startDate}&EndDate=${this.settingData.endDate}`
      apiService.get(patch).then(res => {
        let objItem = [];
        let numberOfObjects = 0;
        res.data.map(item => {
          const isOneDate = res.data.filter(i => i.scheduledDate === item.scheduledDate)
          isOneDate.map((scheduleItem, index) => {
            if (!objItem.filter(i => i.actionID === scheduleItem.actionID).length) {
              if (index > numberOfObjects) numberOfObjects = index;
              const obj = {
                index,
                isDouble: scheduleItem.assignment.isMultipleAssignment,
                isClosed: !!scheduleItem.approvedByManagerID || !!scheduleItem.caseClosedDate,
                actionFileNumber: scheduleItem.actionFileNumber,
                subject: scheduleItem.subject,
                assignment: {
                  distanceToMiles: item.assignment.distanceToMiles,
                  distanceFromMiles: item.assignment.distanceFromMiles,
                },
                actionType: scheduleItem.actionType,
                caseDueDate: scheduleItem.caseDueDate,
                actionID: scheduleItem.actionID,
                start: ScheduleService.getScheduleDate(scheduleItem.scheduledDate, true),
                end: ScheduleService.getScheduleDate(scheduleItem.scheduledDate, false),
              }
              objItem.push(obj)
            }
          })
        })
        if (objItem.length) {
          for (let i = 0; i <= numberOfObjects; i++) {
            const schedule = {
              schedule: [],
            }
            this.scheduleData.push(schedule)
          }
          objItem.map(item => {
            this.scheduleData[item.index].schedule.push(item);
          })
        } else {
          this.scheduleData.push({});
        }
        this.disabledPagination = false;
      })
    },

    getCurrentWeek(val) {
      const currFirst = val ? new Date(val) : new Date();
      const currLast = val ? new Date(val) : new Date();
      const day = currFirst.getDay()
      this.firstData = currFirst.getDate() - (day ? day - 1 : 6);
      this.lastData = this.firstData + 6;
      this.settingData.startDate = ScheduleService.dateFormat(new Date(currFirst.setDate(this.firstData)));
      this.settingData.endDate = ScheduleService.dateFormat(new Date(currLast.setDate(this.lastData)));
    },

    changeWeek(next) {
      if (this.disabledPagination) return;
      const currFirst = new Date(this.inputDate);
      const currLast = new Date(this.inputDate);
      this.firstData = next ? this.firstData + 7 : this.firstData - 7;
      this.lastData = next ? this.lastData + 7 : this.lastData - 7;
      this.settingData.startDate = ScheduleService.dateFormat(new Date(currFirst.setDate(this.firstData)));
      this.settingData.endDate = ScheduleService.dateFormat(new Date(currLast.setDate(this.lastData)));
      this.getUserSchedule();
    },

    setDragenterRowAndIndex(rowIndex, currentIndex) {
      this.dragenterRowIndex = rowIndex;
      this.dragenterColumnIndex = currentIndex;
    },

    disableDragendAnimation(e) {
      e.preventDefault();
    },

    timelineDate(n) {
      const date = this.getHeaderDate(n)
      if (date === ScheduleService.dateFormatter(new Date())) {
          return "Today"
      } else {
          return null
      }
    },

    getHeaderDate(n) {
      let startDate = ScheduleService.addDays(new Date(this.settingData.startDate), n);
      return ScheduleService.dateFormatter(startDate);
    },

    drawCard(newStartDatetime) {
      const stackOfCards = this.scheduleData[this.dragenterRowIndex].schedule
          .filter(i => i.start === newStartDatetime)
      stackOfCards.map((item, index) => item.index = index)
    },

    moveScheduleData(rowIndex, keyNo, unitCnt) {
      let targetData = this.scheduleData[rowIndex].schedule[keyNo];
      if (targetData) {
        let changeDatetimeText = datetimeText => {
          let addMinutes = unitCnt * this.settingData.unit;
          let dateObj = new Date(datetimeText);
          let newDateObj = ScheduleService.addMinutes(dateObj, addMinutes);
          return ScheduleService.datetimeFormatter(newDateObj);
        };
        let newStartDatetime = changeDatetimeText(targetData.start);
        let newEndDatetime = changeDatetimeText(targetData.end);
        if (unitCnt !== 0) {
          targetData.start = newStartDatetime;
          targetData.end = newEndDatetime;
        }
        if (rowIndex !== this.dragenterRowIndex && this.scheduleData[this.dragenterRowIndex]) {
          this.scheduleData[this.dragenterRowIndex].schedule.push(targetData);
          this.scheduleData[rowIndex].schedule.splice(keyNo, 1);
        }
        this.drawCard(newStartDatetime)
      }
    },

    addTimeOff() {
      this.$bvModal.show("modal-add-time-off");
    },

    showAllDayOff() {
      this.showAll = true;
      this.getTimeOff(true);
    },

    hideAllDayOff() {
      this.showAll = false;
      this.getTimeOff(false);
    },

    cancelAddTimeOff() {
      this.$bvModal.hide("modal-add-time-off");
      this.addTimeRange.startDateItem = null;
      this.addTimeRange.endDateItem = null;
    },

    validateAddTimeOff(e) {
      if (this.addTimeRange.startDateItem && this.addTimeRange.endDateItem) {
          if (this.addTimeRange.startDateItem <= this.addTimeRange.endDateItem) {
              this.confirmAddTimeOff();
          } else {
              e.preventDefault()
              this.showToast('danger', 'top-center', 4000, 'The end date must be greater than the start date.');
          }
      } else {
        e.preventDefault()
        this.showToast('danger', 'top-center', 4000, 'Both dates must be filled.');
      }
    },

    confirmAddTimeOff() {
      this.$bvModal.hide("modal-add-time-off");
      const postData = {
          "userId": this.userID,
          "startDate": this.addTimeRange.startDateItem,
          "endDate": this.addTimeRange.endDateItem
      }
      apiService
          .post("day-off", postData)
          .then( res => {
              if (res) {
                  this.addTimeRange.startDateItem = null;
                  this.addTimeRange.endDateItem = null;
              }
              this.getTimeOff()
          })
    },

    getTimeOff(showAll = false) {
        let query = "day-off/" + this.userID
        if (!showAll) {
            query = query + "?from=" + new Date().toISOString().substr(0, 10);
        }
        apiService
            .get(query)
            .then( res => {
                if (res) {
                  this.dayOffs = res.data.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));
                }
            })
    },

    isDayOff(row, n) {
        let dayOff = false;
        if (this.dayOffs.length) {
            const checkedDay = ScheduleService.dateFormatToSort(this.settingData.startDate, n);
            this.dayOffs.map(item => {
                const startDate = ScheduleService.dateFormatToSort(item.startDate)
                const endDate = ScheduleService.dateFormatToSort(item.endDate)
                if (startDate <= checkedDay && endDate >= checkedDay) {
                    dayOff = true
                }
            })
        }
        return dayOff
    },

    removeModal(id) {
        this.idFromRemove = id;
        this.$bvModal.show("modal-remove-time-off");
    },

    confirmRemove() {
        apiService
            .delete("day-off/" + this.idFromRemove)
            .then(res => {
                if (res) {
                    this.idFromRemove = null;
                }
                this.getTimeOff()
            })
    },

    cancelRemove() {
        this.$bvModal.hide("modal-remove-time-off");
    },

    goToViewTimesheet() {
      this.$router.push('/timesheet/user/' + this.userID);
    },
  }
};
</script>

<style scoped>
@import "schedule.css";
.calendar-btn-mode {
  display: flex;
  width: 15rem;
  background: #476dae;
  color: white;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}
</style>