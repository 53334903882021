<template>
  <div id="schedule-wrapper">
    <div v-if="false" class="assignment-title">Assignment</div>
    <ScheduleTab
      @click-event="clickEvent"
    />
  </div>
</template>
<script>

import ScheduleTab from "@/views/schedule/ScheduleTable";

export default {
  components: {
    ScheduleTab,
  },
  data: function() {
    return {};
  },
  methods: {
    clickEvent(startDate, endDate, text, other) {
      console.log("ClickEvent:");
    },
  }
};
</script>

<style>
.sc-rows div.timeline.title {
  padding-top: 0 !important;
  display: flex;
  align-items: center;
}
.sc-rows .sc-rows-scroll {
  top: auto !important;
  bottom: 0;
}
.assignment-title {
  position: absolute;
  color: white;
  z-index: 1;
  top: 3rem;
  left: 2rem;
  border-bottom: 2px solid #f8f8ff;
  padding-bottom: 1rem;
  padding-right: 5.5rem;
  padding-left: 1rem;
}
</style>